.Registo {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  min-height: 100%;

  .form {
    max-width: 700px;
    width: 100%;

    a {
      font-size: 12px;
    }

    .error-message {
      margin-top: 6px;
      font-size: 11px;
      color: #F00;
    }

    .btn-registo {
      background-color: black;
      color: #FFF;
    }

    .form-check {
      .form-check-label {
        font-size: 11px;
        line-height: 2;
        margin-left: 25px;
      }
    }

    .password {
      position: relative;

      .ver_password {
        position: absolute;
        top: 37px;
        right: 10px;
        width: 20px;
        height: 20px;
        cursor: pointer;
        opacity: 0.5;
      }
    }
  }
}