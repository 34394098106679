/* Tables */

.table {
  margin-bottom: 0;

  thead {
    th {
      border-top: 0;
      border-bottom-width: 1px;
      font-family: $type1;
      font-weight: 500;

      i {
        margin-left: 0.325rem;
      }
    }
  }

  th,
  td {
    vertical-align: middle;
    font-size: $default-font-size;
    line-height: 1.3;
    padding: 12px 15px;

    img {
      width: 24px;
      height: 24px;
    }

    .badge {
      margin-bottom: 0;
    }
  }

  &.table-borderless {
    border: none;

    tr, td, th {
      border: none;
    }
  }

  &.table-striped {
    tbody {
      tr {
        &:nth-child(odd) {
          background: $table-accent-bg;
        }

        &:nth-child(even) {
          background: transparent;
        }
      }
    }
  }
}
